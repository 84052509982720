import React, { ReactElement } from "react";
import { GetServerSideProps } from "next";
import dynamic from "next/dynamic";

import AntiVirusChoiceWrapper from "@components/layouts/antiVirusChoiceWrapper";
import TopThree from "@components/antiviruschoice/topThree";
import BestAntivirusRow from "@components/antiviruschoice/bestAntivirusRow";

import { UserOperatingSystemContextProvider } from "src/stores/antiviruschoice/operatingSystem";
import { getPageProps, PageProps } from "@services/initial-calls";
import { useDomainContext } from "@hooks/useDomainContext";
import { affiliateOfferZones } from "@lib/shared/config";

const FAQ = dynamic(() => import("@components/antiviruschoice/faq"));
const MainListing = dynamic(
    () => import("@components/antiviruschoice/mainListing"),
);

export default function Home(props: PageProps): ReactElement {
    const { domain } = useDomainContext();

    return (
        <UserOperatingSystemContextProvider
            userOperatingSystem={props.operatingSystem}
        >
            <AntiVirusChoiceWrapper>
                <div className="text-antivirus-text-black">
                    <TopThree
                        withTitle
                        listing={domain?.defaultOffers}
                        gradient="blue"
                    />
                    <div className="hidden md:flex">
                        <BestAntivirusRow
                            zone={affiliateOfferZones.HOME_PAGE}
                            comparison={false}
                            offer={domain?.defaultOffers[0]}
                            gradient
                        />
                    </div>
                    <MainListing listing={domain?.defaultOffers} />
                    <TopThree
                        withTitle={false}
                        listing={domain?.defaultOffers}
                        gradient="gray"
                    />
                    <FAQ />
                </div>
            </AntiVirusChoiceWrapper>
        </UserOperatingSystemContextProvider>
    );
}

export const getServerSideProps: GetServerSideProps = async (context) => {
    return await getPageProps(context, "home", {
        hasOperatingSystem: true,
        pageTitle: `The Best Antivirus Solutions of ${new Date().getFullYear()}`,
    });
};
