import React from "react";

function Badge() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="110"
            height="59"
            viewBox="0 0 110 59"
        >
            <g fill="none" fillRule="evenodd">
                <g transform="translate(24.619)">
                    <circle cx="30.381" cy="30" r="25" fill="#FFF"></circle>
                    <path
                        fill="#FFC20E"
                        fillRule="nonzero"
                        d="M57.28 25.615c-1.3-1.076-1.656-2.869-.946-4.423l.591-1.434c.947-2.152-.236-4.662-2.484-5.26l-1.656-.478c-1.656-.478-2.72-1.912-2.839-3.586V8.88c-.118-2.39-2.247-4.064-4.494-3.706l-1.538.24c-1.656.239-3.312-.479-4.022-2.033l-.71-1.314C38.118.034 35.397-.683 33.505.752l-1.302.956c-1.3.956-3.193.956-4.494 0L26.526.752c-1.893-1.435-4.613-.718-5.678 1.315l-.71 1.314c-.828 1.435-2.484 2.272-4.14 2.033l-1.537-.24C12.213 4.816 9.966 6.49 9.847 8.88l-.118 1.435c-.118 1.673-1.183 3.107-2.839 3.586l-1.42.358c-2.247.598-3.43 3.108-2.483 5.26l.591 1.434c.592 1.554.237 3.347-1.064 4.423L1.33 26.452a3.887 3.887 0 000 5.857l1.183 1.076c1.3 1.076 1.656 2.869.946 4.423l-.592 1.434c-.946 2.152.237 4.662 2.484 5.26l1.42.478c1.656.478 2.72 1.912 2.839 3.586l.118 1.554c.118 2.39 2.248 4.064 4.495 3.706l1.538-.24c1.656-.239 3.312.479 4.021 2.033l.71 1.314c1.065 2.033 3.785 2.75 5.678 1.315l1.301-.956c1.301-.956 3.194-.956 4.495 0l1.183.956c1.892 1.435 4.613.718 5.678-1.315l.71-1.314c.827-1.435 2.483-2.272 4.14-2.033l1.537.24c2.247.358 4.495-1.315 4.613-3.706l.118-1.554c.119-1.674 1.183-3.108 2.84-3.586l1.419-.359c2.247-.597 3.43-3.108 2.484-5.26l-.592-1.434c-.591-1.554-.236-3.347 1.065-4.423l1.183-1.075a3.89 3.89 0 000-5.858l-1.065-.956zm-27.442 23.19c-10.528 0-19.044-8.607-19.044-19.245 0-10.639 8.516-19.245 19.044-19.245 10.527 0 19.044 8.606 19.044 19.245 0 10.638-8.517 19.245-19.044 19.245zM43.559 29.56c0 7.65-6.15 13.866-13.721 13.866-7.57 0-13.721-6.216-13.721-13.866 0-7.65 6.15-13.866 13.72-13.866 7.571 0 13.722 6.215 13.722 13.866z"
                    ></path>
                </g>
                <path
                    fill="#FF4F4F"
                    d="M108.9 37.21c.473.598.119 1.554-.71 1.554H87.592c-.118-.598-.236-1.195-.473-1.673L87 36.97l.118-.12c2.13-1.793 3.312-4.542 3.312-7.291 0-2.75-1.183-5.499-3.193-7.292l-.119-.12.119-.119c.236-.598.473-1.195.591-1.913h20.48c.829 0 1.302.957.71 1.554l-5.914 7.531a.583.583 0 000 .837l5.796 7.172zM.383 37.21c-.473.598-.119 1.554.71 1.554h20.598c.118-.598.236-1.195.473-1.673l.119-.121-.118-.12c-2.13-1.793-3.312-4.542-3.312-7.291 0-2.75 1.183-5.499 3.193-7.292l.119-.12-.119-.119c-.236-.598-.473-1.195-.591-1.913H.975c-.829 0-1.302.957-.71 1.554L6.179 29.2a.583.583 0 010 .837L.383 37.21z"
                ></path>
            </g>
        </svg>
    );
}

export default Badge;
