import React from "react";
import styles from "./topThree.module.scss";
import Badge from "../svg/badge";
import { ListingItem } from "src/lib/shared/types";
import AffiliateOfferUrl from "@components/shared/affiliateOfferUrl";
import { affiliateOfferZones } from "@lib/shared/config";
interface Props {
    withTitle: boolean;
    listing?: ListingItem[] | undefined;
    gradient?: "blue" | "gray";
}

const TopThree: React.FC<Props> = ({ withTitle, listing, gradient }) => {
    return (
        <div
            className={`md:pb-14 flex flex-col justify-center items-center py-4 px-4 ${
                gradient === "blue"
                    ? styles["bg-top-gradient-blue"]
                    : styles["bg-top-gradient-gray"]
            }`}
        >
            {withTitle && (
                <h1
                    className={`${styles.header} text-center 
                        text-xl md:text-3xl font-bold mt-4 mb-6 lg:mb-12`}
                >
                    The Top 3 Antivirus Solutions
                </h1>
            )}
            <div
                className={`justify-center items-center w-full flex flex-col lg:flex-row`}
            >
                {listing &&
                    listing.slice(0, 3).map((item, idx) => {
                        return (
                            <div
                                key={idx}
                                className={`flex flex-col mb-3 px-10 py-3 lg:px-0 text-xs justify-around w-full items-center ${styles.offer} h-40 lg:h-52 rounded-md relative lg:w-4/12`}
                            >
                                <img
                                    className="max-w-[150px]"
                                    src={item?.logo?.fullPath}
                                    alt=""
                                />
                                <p className="text-center lg:w-4/6 mb-3">
                                    {item.description}
                                </p>
                                <AffiliateOfferUrl
                                    zone={affiliateOfferZones.HOME_PAGE}
                                    target="_blank"
                                    offer={item}
                                >
                                    <button
                                        className={`rounded-md relative font-bold flex items-center ${styles.button} px-5 py-3
                                        text-sm`}
                                    >
                                        Get it Now
                                        <img
                                            className="w-4 ml-2"
                                            src="/assets/right-arrow.svg"
                                            alt=""
                                        />
                                    </button>
                                </AffiliateOfferUrl>

                                <div
                                    className={`hidden ${styles.badge} ${
                                        idx === 0 ? "lg:block" : ""
                                    }`}
                                >
                                    <Badge />
                                </div>
                            </div>
                        );
                    })}
            </div>
        </div>
    );
};
export default TopThree;
